
import './style.scss';

function importAll(r) {
  return r.keys().map(r);
}

const fonts = importAll(require.context('./fonts/', false, /\.ttf$/));
const templates = importAll(require.context('./templates/models/', false, /\.(png|jpe?g|svg)$/));
const designs = importAll(require.context('./templates/designs/', false, /\.(png|jpe?g|svg)$/));
const templates3d = importAll(require.context('./templates/3d/', false, /\.(png|jpe?g|svg)$/));
const templates2d = importAll(require.context('./templates/2d/', false, /\.(png|jpe?g|svg)$/));

import './logo/icon.svg';
import './logo/icon_white.svg';
import './logo/logo.svg';
import './logo/logo.png';
import './logo/logo_transparent_crop.png';
import './img/brad-neathery-248309-unsplash.jpg'
import './img/brad-neathery-303623-unsplash.jpg'
import './img/glenn-carstens-peters-282287-unsplash.jpg'
import './img/undraw_voice_interface_eckp.svg'
import './img/undraw_designer_girl_xdmq.svg'
import './img/undraw_time_management_30iu.svg'
import './img/undraw_confirmation_2uy0.svg'
import './img/undraw_not_found_60pq.svg'
import './img/undraw_server_down_s4lk.svg'
import './icons/click.svg'
import './icons/clock.svg'
import './icons/parameters.svg'

const blog_article_1 = importAll(require.context('./img/blog/tutoriel-marque-places-anniversaire/', false, /\.(png|jpe?g|svg)$/));

// FA icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faUpload, faCheck, faAlignLeft, faImage, faCut } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faCcMastercard, faCcVisa, faCcAmex } from '@fortawesome/free-brands-svg-icons'

import Vue from 'vue';

// toggle menu
const menuApp = new Vue({
  el: '#navbar',
  delimiters: ['[[', ']]'],
  data: function () {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleMenu: function() {
      this.isActive = !this.isActive;
    }
  },
  mounted: function () {
    library.add(
      faUpload,
      faFacebook,
      faTwitter,
      faCheck,
      faCcAmex,
      faCcVisa,
      faCcMastercard,
      faAlignLeft,
      faImage,
      faCut,
    )
    dom.watch()
  }
});

const cookieApp = new Vue({
  el: '#cookies',
  delimiters: ['[[', ']]'],
  data: function () {
    return {
      accepted: localStorage.getItem('cookies-accepted'),
    }
  },
  methods: {
    accept: function() {
      const now = new Date();
      this.accepted = true;
      localStorage.setItem('cookies-accepted', this.accepted);
      localStorage.setItem('cookies-accepted-date', now.toUTCString());
    }
  }
});
